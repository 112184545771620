<template>
  <div>
    <v-row>
      <!-- this title should change based on users role -->
      <base-overview-card
        title="Courses"
        model="courses"
        @onCreateNew="handleOnCreateNew"
      >
        <v-tabs v-model="tab" icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            Pending Requests
            <v-icon>mdi-clock</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            Approved
            <v-icon>mdi-check</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="mt-5">
          <v-tab-item value="tab-1">
            <courses-table
              status="pending"
              @changeStatus="changeStatus"
              @onEdit="handleEditEntry"
              @onDelete="handleDeleteEntry"
            />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <courses-table
              status="approved"
              @changeStatus="changeStatus"
              @onEdit="handleEditEntry"
              @onDelete="handleDeleteEntry"
            />
          </v-tab-item>
        </v-tabs-items>
      </base-overview-card>
      <!--TODO: this should be shown for admin users -->
      <base-overview-card
        title="Budgets"
        model="budgets"
        @onCreateNew="handleOnCreateNew"
      >
        <budgets-table
          @onDelete="handleDeleteEntry"
          @onEdit="handleEditEntry"
        />
      </base-overview-card>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="baseDialog" persistent max-width="800px">
        <component
          :is="currentFormComponent"
          :itemToEdit="itemToEdit"
          @close="handleCloseDialog"
          @save="handleOnSave"
          @update="handleOnUpdate"
        ></component>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <base-confirm-dialog
        :isOpen="confirmDialog"
        title="Confirm"
        body="Are you sure you want to delete this item"
        @confirm="handleOnConfirmDelete"
        @cancel="resetAndCloseDialog"
      ></base-confirm-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Dashboard',

  components: {
    BudgetsForm: () => import('@/components/BudgetsForm'),
    BaseConfirmDialog: () => import('@/components/BaseConfirmDialog'),
    CoursesForm: () => import('@/components/CoursesForm'),
    BaseOverviewCard: () => import('@/components/BaseOverviewCard'),
    TheSideMenu: () => import('@/components/TheSideMenu'),
    CoursesTable: () => import('@/components/CoursesTable'),
    BudgetsTable: () => import('@/components/BudgetsTable'),
  },

  data: () => ({
    currentFormComponent: '',
    isOpen: true,
    baseDialog: false,
    confirmDialog: false,
    drawer: null,
    itemToEdit: {},
    itemToDelete: null,
    tab: null,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  }),

  computed: {
    ...mapState('budgets', ['budgets']),
    ...mapState('courses', ['courses']),
    modelsInfo() {
      return {
        budgets: {
          formComponent: 'BudgetsForm',
          deleteMethod: this.deleteBudget,
          saveMethod: this.saveBudget,
          updateMethod: this.updateBudget,
        },
        courses: {
          formComponent: 'CoursesForm',
          deleteMethod: this.deleteCourse,
          saveMethod: this.saveCourse,
          updateMethod: this.updateCourse,
        },
      };
    },
  },

  methods: {
    ...mapActions('courses', ['deleteCourse', 'saveCourse', 'updateCourse']),
    ...mapActions('budgets', ['saveBudget', 'deleteBudget', 'updateBudget']),
    changeStatus(collection, id, newStatus) {
      const collectionItem = this[collection].find((item) => item.id === id);
      collectionItem.status = newStatus;
      this.modelsInfo[collection].updateMethod(collectionItem);
    },
    handleCloseDialog() {
      this.baseDialog = false;
      this.itemToEdit = {};
      this.deleteId = null;
    },
    handleOnCreateNew(model) {
      this.baseDialog = true;
      this.currentFormComponent = this.modelsInfo[model].formComponent;
    },
    handleOnSave({ collection, formData }) {
      this.modelsInfo[collection].saveMethod(formData);
      this.baseDialog = false;
    },
    handleOnUpdate({ collection, formData }) {
      this.modelsInfo[collection].updateMethod(formData);
      this.baseDialog = false;
    },
    handleOnConfirmDelete() {
      const { id, model } = this.itemToDelete;
      this.modelsInfo[model].deleteMethod(id);
      this.itemToDelete = null;
      this.confirmDialog = false;
    },
    handleEditEntry(model, id) {
      this.baseDialog = true;
      this.itemToEdit = this[model].find((item) => item.id === id);
      this.currentFormComponent = this.modelsInfo[model].formComponent;
    },
    handleDeleteEntry(model, id) {
      this.confirmDialog = true;
      this.itemToDelete = { id, model };
    },
    resetAndCloseDialog() {
      this.itemtoDelete = null;
      this.confirmDialog = false;
    },
    setDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
